video {
  width: 75vw;
  height: 75vh;
}

.video {
  position: absolute;
  transition: opacity 1s ease-in-out;
}

.video-visible {
  opacity: 1;
}

.video-hidden {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  /* z-index: 0; Hidden video goes to the background */
}

.video-main {
  /* z-index: 2; */
}

.video-listening-loop {
  opacity: 1 !important;
  /* z-index: 1; Keep listening loop behind the main video by default */
}
@font-face {
  font-family: "CircularStd-Regular";
  src: local("CircularStd-Book2"),
  url("./fonts/circular/CircularStd-Book2.otf") format("opentype");
}

@font-face {
  font-family: "CircularStd-Light";
  src: local("CircularStd-Light2"),
  url("./fonts/circular/CircularStd-Light2.otf") format("opentype");
}

@font-face {
  font-family: "CircularStd-Medium";
  src: local("CircularStd-Medium2"),
  url("./fonts/circular/CircularStd-Medium2.otf") format("opentype");
}

@font-face {
  font-family: "Courier";
  src: local("Courier"),
  url("./fonts/courier/Courier-Regular.ttf") format("opentype");
}

.App {
  text-align: center;
}

body {
  background-color: #F4F4F4;
}